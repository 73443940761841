const yearsToDownload = ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022'];

const dimensions = ['Date', 'Month', 'Year', 'Year-Month', 'Park', 'Category', 'Channel', 'Weather_Code'];

const filterDimensions_salesAtt = ['Park', 'Year', 'Year-Month', 'Category', 'Channel'];
const filterDimensions_passBase = ['Park', 'Year', 'Year-Month', 'ParkList', 'Pass_Type', 'Pass_SubType', 'Pass_Season', 'PassTier'];

const dimSortings = {
  Park: ['SWF', 'APO', 'DCO', 'BGT', 'AIT', 'SWC', 'SPC', 'APC', 'SWT', 'APT', 'BGW', 'WCW', 'SPL'],
  Category: ['SDT', 'MDT', 'FC', 'Pass', 'Free'],
  Channel: ['Internet - Consumer Direct', 'In-Park', 'Contact Center', 'Business Partner'],
};

// const colorsArray = ['#2965CC', '#29A634', '#D99E0B', '#D13913', '#8F398F', '#00B3A4', '#DB2C6F', '#9BBF30', '#96622D', '#7157D9'];

const weatherCodeWeights = [
  {
    Park: 'AIT',
    Day: 1,
    Weight: 0.093,
  },
  {
    Park: 'AIT',
    Day: 2,
    Weight: 0.103,
  },
  {
    Park: 'AIT',
    Day: 3,
    Weight: 0.112,
  },
  {
    Park: 'AIT',
    Day: 4,
    Weight: 0.122,
  },
  {
    Park: 'AIT',
    Day: 5,
    Weight: 0.149,
  },
  {
    Park: 'AIT',
    Day: 6,
    Weight: 0.26,
  },
  {
    Park: 'AIT',
    Day: 7,
    Weight: 0.162,
  },
  {
    Park: 'APC',
    Day: 1,
    Weight: 0.096,
  },
  {
    Park: 'APC',
    Day: 2,
    Weight: 0.098,
  },
  {
    Park: 'APC',
    Day: 3,
    Weight: 0.099,
  },
  {
    Park: 'APC',
    Day: 4,
    Weight: 0.109,
  },
  {
    Park: 'APC',
    Day: 5,
    Weight: 0.131,
  },
  {
    Park: 'APC',
    Day: 6,
    Weight: 0.253,
  },
  {
    Park: 'APC',
    Day: 7,
    Weight: 0.215,
  },
  {
    Park: 'APO',
    Day: 1,
    Weight: 0.108,
  },
  {
    Park: 'APO',
    Day: 2,
    Weight: 0.101,
  },
  {
    Park: 'APO',
    Day: 3,
    Weight: 0.106,
  },
  {
    Park: 'APO',
    Day: 4,
    Weight: 0.112,
  },
  {
    Park: 'APO',
    Day: 5,
    Weight: 0.123,
  },
  {
    Park: 'APO',
    Day: 6,
    Weight: 0.231,
  },
  {
    Park: 'APO',
    Day: 7,
    Weight: 0.218,
  },
  {
    Park: 'APT',
    Day: 1,
    Weight: 0.099,
  },
  {
    Park: 'APT',
    Day: 2,
    Weight: 0.096,
  },
  {
    Park: 'APT',
    Day: 3,
    Weight: 0.103,
  },
  {
    Park: 'APT',
    Day: 4,
    Weight: 0.105,
  },
  {
    Park: 'APT',
    Day: 5,
    Weight: 0.151,
  },
  {
    Park: 'APT',
    Day: 6,
    Weight: 0.262,
  },
  {
    Park: 'APT',
    Day: 7,
    Weight: 0.186,
  },
  {
    Park: 'BGT',
    Day: 1,
    Weight: 0.108,
  },
  {
    Park: 'BGT',
    Day: 2,
    Weight: 0.086,
  },
  {
    Park: 'BGT',
    Day: 3,
    Weight: 0.09,
  },
  {
    Park: 'BGT',
    Day: 4,
    Weight: 0.103,
  },
  {
    Park: 'BGT',
    Day: 5,
    Weight: 0.136,
  },
  {
    Park: 'BGT',
    Day: 6,
    Weight: 0.279,
  },
  {
    Park: 'BGT',
    Day: 7,
    Weight: 0.196,
  },
  {
    Park: 'BGW',
    Day: 1,
    Weight: 0.103,
  },
  {
    Park: 'BGW',
    Day: 2,
    Weight: 0.096,
  },
  {
    Park: 'BGW',
    Day: 3,
    Weight: 0.103,
  },
  {
    Park: 'BGW',
    Day: 4,
    Weight: 0.113,
  },
  {
    Park: 'BGW',
    Day: 5,
    Weight: 0.157,
  },
  {
    Park: 'BGW',
    Day: 6,
    Weight: 0.238,
  },
  {
    Park: 'BGW',
    Day: 7,
    Weight: 0.19,
  },
  {
    Park: 'DCO',
    Day: 1,
    Weight: 0.138,
  },
  {
    Park: 'DCO',
    Day: 2,
    Weight: 0.132,
  },
  {
    Park: 'DCO',
    Day: 3,
    Weight: 0.135,
  },
  {
    Park: 'DCO',
    Day: 4,
    Weight: 0.137,
  },
  {
    Park: 'DCO',
    Day: 5,
    Weight: 0.147,
  },
  {
    Park: 'DCO',
    Day: 6,
    Weight: 0.161,
  },
  {
    Park: 'DCO',
    Day: 7,
    Weight: 0.149,
  },
  {
    Park: 'SPC',
    Day: 1,
    Weight: 0.096,
  },
  {
    Park: 'SPC',
    Day: 2,
    Weight: 0.098,
  },
  {
    Park: 'SPC',
    Day: 3,
    Weight: 0.099,
  },
  {
    Park: 'SPC',
    Day: 4,
    Weight: 0.109,
  },
  {
    Park: 'SPC',
    Day: 5,
    Weight: 0.131,
  },
  {
    Park: 'SPC',
    Day: 6,
    Weight: 0.253,
  },
  {
    Park: 'SPC',
    Day: 7,
    Weight: 0.215,
  },
  {
    Park: 'SPL',
    Day: 1,
    Weight: 0.13,
  },
  {
    Park: 'SPL',
    Day: 2,
    Weight: 0.095,
  },
  {
    Park: 'SPL',
    Day: 3,
    Weight: 0.099,
  },
  {
    Park: 'SPL',
    Day: 4,
    Weight: 0.122,
  },
  {
    Park: 'SPL',
    Day: 5,
    Weight: 0.177,
  },
  {
    Park: 'SPL',
    Day: 6,
    Weight: 0.214,
  },
  {
    Park: 'SPL',
    Day: 7,
    Weight: 0.164,
  },
  {
    Park: 'SWC',
    Day: 1,
    Weight: 0.114,
  },
  {
    Park: 'SWC',
    Day: 2,
    Weight: 0.085,
  },
  {
    Park: 'SWC',
    Day: 3,
    Weight: 0.086,
  },
  {
    Park: 'SWC',
    Day: 4,
    Weight: 0.088,
  },
  {
    Park: 'SWC',
    Day: 5,
    Weight: 0.122,
  },
  {
    Park: 'SWC',
    Day: 6,
    Weight: 0.267,
  },
  {
    Park: 'SWC',
    Day: 7,
    Weight: 0.238,
  },
  {
    Park: 'SWF',
    Day: 1,
    Weight: 0.111,
  },
  {
    Park: 'SWF',
    Day: 2,
    Weight: 0.097,
  },
  {
    Park: 'SWF',
    Day: 3,
    Weight: 0.099,
  },
  {
    Park: 'SWF',
    Day: 4,
    Weight: 0.102,
  },
  {
    Park: 'SWF',
    Day: 5,
    Weight: 0.13,
  },
  {
    Park: 'SWF',
    Day: 6,
    Weight: 0.254,
  },
  {
    Park: 'SWF',
    Day: 7,
    Weight: 0.206,
  },
  {
    Park: 'SWT',
    Day: 1,
    Weight: 0.11,
  },
  {
    Park: 'SWT',
    Day: 2,
    Weight: 0.098,
  },
  {
    Park: 'SWT',
    Day: 3,
    Weight: 0.095,
  },
  {
    Park: 'SWT',
    Day: 4,
    Weight: 0.108,
  },
  {
    Park: 'SWT',
    Day: 5,
    Weight: 0.146,
  },
  {
    Park: 'SWT',
    Day: 6,
    Weight: 0.29,
  },
  {
    Park: 'SWT',
    Day: 7,
    Weight: 0.152,
  },
  {
    Park: 'WCW',
    Day: 1,
    Weight: 0.12,
  },
  {
    Park: 'WCW',
    Day: 2,
    Weight: 0.112,
  },
  {
    Park: 'WCW',
    Day: 3,
    Weight: 0.116,
  },
  {
    Park: 'WCW',
    Day: 4,
    Weight: 0.129,
  },
  {
    Park: 'WCW',
    Day: 5,
    Weight: 0.142,
  },
  {
    Park: 'WCW',
    Day: 6,
    Weight: 0.195,
  },
  {
    Park: 'WCW',
    Day: 7,
    Weight: 0.187,
  },
];

const priorYears = {
  CY: {
    color: {
      dark: '#3b93d3',
      light: '#1f76b4',
    },
    deltaYears: 0,
    deltaDays: 0,
  },
  PY: {
    color: {
      dark: '#F29D49',
      light: '#BF7326',
    },
    deltaYears: -1,
    deltaDays: -364,
  },
  '2PY': {
    color: {
      dark: '#F55656',
      light: '#C23030',
    },
    deltaYears: -2,
    deltaDays: -364 * 2,
  },
  '3PY': {
    color: {
      dark: '#9179F2',
      light: '#634DBF',
    },
    deltaYears: -3,
    deltaDays: -364 * 3,
  },
  '3PY*': {
    color: {
      dark: '#9179F2',
      light: '#634DBF',
    },
    deltaYears: -3,
    deltaDays: -364 * 3 - 7,
  },
  // '4PY': {
  //   color: {
  //     dark: '#43BF4D',
  //     light: '#238C2C',
  //   },
  //   deltaYears: -4,
  //   deltaDays: -364 * 4,
  // },
  '4PY*': {
    color: {
      dark: '#43BF4D',
      light: '#238C2C',
    },
    deltaYears: -4,
    deltaDays: -364 * 4 - 7,
  },
  // '5PY': {
  //   color: {
  //     dark: '#BFCCD6',
  //     light: '#8A9BA8',
  //   },
  //   deltaYears: -5,
  //   deltaDays: -364 * 5,
  // },
  '5PY*': {
    color: {
      dark: '#BFCCD6',
      light: '#8A9BA8',
    },
    deltaYears: -5,
    deltaDays: -364 * 5 - 7,
  },
  '6PY*': {
    color: {
      dark: '#14CCBD',
      light: '#00B3A4',
    },
    deltaYears: -6,
    deltaDays: -364 * 6 - 7,
  },
};

const timeGroupings = ['Day', 'Week', 'Month', 'Quarter', 'Year'];

const metricFormatMatrix = {
  'Sales units': '0.0 a',
  Attendance: '0.0 a',
  'Sales Revenue': '$0.0 a',
  'Admission Revenue': '$0.0 a',
  'Sales per cap': '$0.00 a',
  'Admission per cap': '$0.00 a',
  'Weather Code': '0.0',
  'Real Weather - Max Temperature': '0.0',
  'Real Weather - Max Feels Like Temperature': '0.0',
  'Real Weather - Precipitation': '0.0',
  'Real Weather - Cloud Cover': '0.0',
};

const metricFormatMatrix_exact = {
  'Sales units': '0,0',
  Attendance: '0,0',
  'Sales Revenue': '$0,0',
  'Admission Revenue': '$0,0',
  'Sales per cap': '$0.00',
  'Admission per cap': '$0.00',
  'Weather Code': '0.0',
  'Real Weather - Max Temperature': '0.0',
  'Real Weather - Max Feels Like Temperature': '0.0',
  'Real Weather - Precipitation': '0.0',
  'Real Weather - Cloud Cover': '0.0',
};

export {
  yearsToDownload,
  dimensions,
  filterDimensions_salesAtt,
  filterDimensions_passBase,
  dimSortings,
  weatherCodeWeights,
  priorYears,
  timeGroupings,
  metricFormatMatrix,
  metricFormatMatrix_exact,
};
