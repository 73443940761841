/* eslint-disable no-param-reassign */
import produce from 'immer';
import { buildNumberReset } from 'utils/appDefinitions';
import ioActionTypes from './Actions/ioActionTypes';
import { dimSortings, dimensionGroupings, timeGroupings } from './utils/constants';

const localState = localStorage.getItem('ioState');
const loadedState = localState && JSON.parse(localState);
const firstState = {
  trafficConversion: {
    options: {
      global: {
        priorYears: [],
      },
      timeSeries: {
        groupBy: timeGroupings[1],
        orderBy: 'Date',
        toggleTable: false,
        toggleCumulative: false,
      },
      barChart: {
        groupBy: dimensionGroupings[0],
        orderBy: null,
        toggleTable: false,
        toggleCumulative: false,
      },
    },
    filtersSelection: {
      Park: [dimSortings.Park[0]],
      Year: ['2024'],
      // dimensionGrouping: [dimensionGroupings[0]],
    },
  },
  buildNumber: process.env.REACT_APP_BUILD_NUMBER || 'local',
};

// "Repair" state loaded from local storage in case a property is missing
if (loadedState) {
  for (const categoryName of Object.keys(firstState)) {
    for (const optionName of Object.keys(firstState[categoryName])) {
      if (!loadedState[categoryName]) {
        loadedState[categoryName] = firstState[categoryName];
      } else if (!loadedState[categoryName][optionName]) {
        loadedState[categoryName][optionName] = firstState[categoryName][optionName];
      }
    }
  }
}

// use firstState if build number is below the threshold value
const initialState = loadedState && (loadedState?.buildNumber === 'local' || loadedState?.buildNumber >= buildNumberReset) ? loadedState : firstState;

// eslint-disable-next-line consistent-return
const ioReducer = produce((draft = initialState, action) => {
  switch (action.type) {
    case ioActionTypes.UPDATE_OPTION:
      draft[action.module].options[action.subModule][action.optionName] = action.newValue;
      break;
    case ioActionTypes.UPDATE_FILTER:
      draft[action.module].filtersSelection[action.filterName] = action.newItems;
      break;
    // case ioActionTypes.TOGGLE_FILE_TO_DOWNLOAD: {
    //   const oldValue = draft[action.module].dataParameters.filesToDownload.find((el) => el.fileName === action.fileName).value;
    //   draft[action.module].dataParameters.filesToDownload.find((el) => el.fileName === action.fileName).value = !oldValue;
    //   break;
    // }
    default:
      return draft;
  }
  localStorage.setItem('ioState', JSON.stringify(draft));
});

export default ioReducer;
