import localforage from 'localforage';
import { sortPerList } from '../utils/utils';
import ioAPI from '../API/ioAPI';
import ioActionTypes from './ioActionTypes';
import { dimSortings } from '../utils/constants';

const downloadFullData = async (token, onDownloadProgress) => {
  try {
    const { data } = await ioAPI.downloadData(token, { type: 'full' }, onDownloadProgress);
    // save data to localforage
    localforage.setItem('Io_trafficConversion_fullData', data);
    return { data, err: null };
  } catch (err) {
    // console.log(`Error: ${err}`);
    return { err: err.message };
  }
};

const downloadRunDetails = async (token) => {
  const { runDetails } = await ioAPI.downloadRunDetails(token);
  return runDetails;
};

const updateOption = ({ module, subModule, optionName, newValue }) => ({
  type: ioActionTypes.UPDATE_OPTION,
  module,
  subModule,
  optionName,
  newValue,
});

const updateFilter = ({ module, filterName, newItems }) => {
  const sortOrder = dimSortings[filterName];
  const newItemsSorted = sortPerList(newItems, sortOrder);

  return {
    type: ioActionTypes.UPDATE_FILTER,
    module,
    filterName,
    newItems: newItemsSorted,
  };
};

export default {
  downloadFullData,
  downloadRunDetails,
  updateOption,
  updateFilter,
};
